import React from "react";

const ContactUs = () => {
  return (
    <>
      <section class="contact-area third-contact-bg">
        <div class="container custom-container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="third-contact-wrap">
                <div class="third-title-style">
                  <h2 style={{ color: "#000" }}>
                    Contact<span>us</span>
                  </h2>
                  <div class="inner">
                    <ul>
                      <li>
                        <b>Our location :</b> NKPR FINTECH SOLUTIONS PVT LTD. 1"
                        Flr, Office no 1, Ganesh Smruti Blgd, Gaothan, Virar
                        west Thane, Maharashtara 401303
                      </li>
                      <li>
                        <b>Phone :</b> +91 8149696437
                      </li>
                      <li>
                        <b>Email :</b> support@myfantasy11.net
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <form action="#" class="third-contact-form">
                <div class="row">
                  <div class="col-sm-6">
                    <input type="text" placeholder="Your Name" />
                  </div>
                  <div class="col-sm-6">
                    <input type="text" placeholder="Your Phone" />
                  </div>
                </div>
                <textarea
                  name="message"
                  id="message"
                  placeholder="Wight Message"
                ></textarea>
                <button class="btn rotated-btn">submit</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
