import React from 'react'
const data = [
    {
      id:1,
      title:"SELECT A MATCH",
      subtitle:"Select an upcoming match of your choice"
    },
    {
      id:2,
      title:"JOIN CONTESTS",
      subtitle:"Participate in Cash or Practice Contests."
    },
    {
      id:3,
      title:"CREATE YOUR TEAM",
      subtitle:"Use your sports knowledge and check player stats on the platform to create a team using 100 credits"
    }
  ]
  
const Info = () => {
    return (
        <>
             <section id="upcoming-game-two"  style={{backgroundImage:"url(media/background/3.jpg)"}}>
			<div class="container">
				<div class="section-title">
					<h2 class="title wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="0.2s">HOW TO <span>PLAY</span></h2>
					<span class="delemitter wow zoomIn" data-wow-duration="1000ms" data-wow-delay="0.3s" data-bg-image="media/banner/s1.png" style={{backgroundImage: "url(media/banner/s1.png)"}}></span>

					<div class="section-content wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="0.4s">
						
					</div>
				</div>


				<div class="row">
          {data.map((item, index)=>(
            <div class="col-lg-4">
						<div class="upc-game-two wow fadeInUp text-center" data-wow-duration="1000ms" data-wow-delay="0.8s">
							<div class="">
								<a className="">
                 <div className="" style={{background:"#f68b1f", width:"100px", height:"100px", display: "table-cell", verticalAlign: "middle", borderRadius: "50%", fontSize: "46px" }}>
                  {item.id}
                 </div>
                </a>
							</div>

							<div>
								<h3 className=" mt-3">{item.title}</h3>
								<p className="text-light">
								{item.subtitle}
								</p>
							</div>
						</div>
					</div>
          ))}
					
				</div>
			</div>
		</section>
        </>
    )
}

export default Info