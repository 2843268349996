import React from "react";
import { Link, NavLink } from "react-router-dom";
import {
  RiFacebookFill,
  RiLinkedinFill,
  RiInstagramFill,
} from "react-icons/ri";
import { GrTwitter } from "react-icons/gr";

const Header = () => {
  return (
    <>
      <header className="header header-one">
        <div className="top-menu">
          <div className="container">
            <div className="top-menu-wrap">
              <div className="row">
                <div className="col-md-8">
                  <div className="top-menu-left">
                    <ul className="head-social-link">
                      <li>
                        <Link to="/">
                          <RiFacebookFill />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <GrTwitter />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <RiLinkedinFill />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <RiInstagramFill />
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="header-text">
                    <span className="tour-title">ICC Men's </span>
                    <h4 className="ah-headline slide">
                      <span
                        className="ah-words-wrapper"
                        style={{ width: "251.925px" }}
                      >
                        <b className="is-hidden">T20 World Cup, 2024</b>
                      </span>
                    </h4>
                  </div>
                </div>

                <div className="col-md-4">
                  <ul className="user-link nav justify-content-end">
                    {/* <li>
										<Link to="/" data-toggle="modal" data-target="#signInModal">
									<i className="fa fa-user"></i>
									Login
								</Link>
									</li>
									<li>
										<Link to="/" data-toggle="modal" data-target="#signUpModal">
									<i className="fa fa-sign-in"></i>
									Sign Up
								</Link>
									</li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="nav">
          <div className="container">
            <div className="nav-wrap">
              <div id="site-logo">
                <Link to="/">
                  <img
                    src="img/logo/logo.png"
                    alt="Site Logo"
                    style={{ width: "200px" }}
                  />
                </Link>
              </div>
              <ul
                className="tim-nav text-right"
                style={{ background: "#03424f" }}
              >
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/about">About Us</NavLink>
                </li>
                {/* <li>
                          <NavLink to="/players">Players</NavLink>
                        </li> */}
                <li>
                  <NavLink to="/play">How To Play</NavLink>
                </li>
                <li>
                  <NavLink to="/contact">contact</NavLink>
                </li>
                <li>
                  <a href="/media/myfantasy11.apk">Downlod</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
