import React from "react";
import Breadcrumb from "../components/Breadcrumb";

const Terms = () => {
  return (
    <>
      <Breadcrumb title="Terms and Conditions" />
      <section className="contact-area third-contact-bg">
        <div className="container custom-container">
          <div className="col-lg-12">
            <h3 className="text-dark">Consent To The Use Of Cookies</h3>
            <p className="text-dark">
              A cookie is a small text file that uniquely identifies your
              browser.
            </p>
            <p className="text-dark">
              When you visit the website, cookies may be stored on your computer
              to enhance your browsing experience. Additionally, cookies may be
              used for authentication, game management, data analysis, and
              security purposes.
            </p>
            <p className="text-dark">
              Cookies may also be assigned by advertisers when you click on
              advertisements displayed on various websites. These cookies are
              controlled by the respective advertisers, not NKRP FINTECH
              SOLUTIONS PVT LTD.
            </p>

            <h3 className="mt-3 text-dark">Consent To Email Communication</h3>
            <p className="text-dark">
              By registering your email address with NKRP FINTECH SOLUTIONS PVT
              LTD, you agree to receive email communication. You also
              acknowledge that when using our referral program to refer someone,
              emails may be sent to the referred person on your behalf, with
              your email address as the sender.
            </p>
            <p className="text-dark">
              NKPR FINTECH SOLUTIONS PVT LTD may access and store information
              related to your contacts to periodically send invitations and
              other promotions.
            </p>
            <p className="text-dark">
              Excluded Locations: Assam, Nagaland, Andhra Pradesh, Odisha,
              Sikkim, Telangana and Arunachal Pradesh
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Terms;
