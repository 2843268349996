import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import Info from '../components/Info'
import Steps from '../components/Steps'
import AllTeam from '../components/AllTeam'

const Play = () => {
    return (
        <>
            <Breadcrumb title="How To Play" />

            <Steps />
            <Info />
            <AllTeam />
        </>
    )
}

export default Play