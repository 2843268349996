import React from "react";

const Match = () => {
  return (
    <>

<section id="tournaments">
			<div class="container">
  <div className="row">
  <div className="col-md-4">
  <div class="swiper-slide swiper-slide-duplicate swiper-slide-next" data-swiper-slide-index="0">
							<div class="trurnament-inner wow fadeInRight" data-wow-duration="1000ms">
								<div class="torurmant clearfix">
									<div class="tgame game-item">
										<img src="img/team/1.png" alt="Game"/>
										<h4>India</h4>
									</div>

									<div class="game-item vs">
										<h4>Vs</h4>
									</div>

									<div class="tgame game-item">
										<img src="img/team/3.png" alt="Game"/>
										<h4>Australia</h4>
									</div>
								</div>

								<div class="tournaments-details clearfix">
									<div class="tournamant-date">
										<div class="date w-100 text-left">
											<h4>Jun 25</h4>
                      <p>ICC Men's T20 World Cup, 2024</p>
										</div>
									</div>
								</div>
							</div>
						</div>
  </div>
  <div className="col-md-4">
  <div class="swiper-slide swiper-slide-duplicate swiper-slide-next" data-swiper-slide-index="0">
							<div class="trurnament-inner wow fadeInRight" data-wow-duration="1000ms">
								<div class="torurmant clearfix">
									<div class="tgame game-item">
										<img src="img/team/5.png" alt="Game"/>
										<h4>Afghanistan</h4>
									</div>

									<div class="game-item vs">
										<h4>Vs</h4>
									</div>

									<div class="tgame game-item">
										<img src="img/team/6.png" alt="Game"/>
										<h4>Bangladesh</h4>
									</div>
								</div>

								<div class="tournaments-details clearfix">
									<div class="tournamant-date">
										<div class="date w-100 text-left">
											<h4>Jun 25</h4>
                      <p>ICC Men's T20 World Cup, 2024</p>
										</div>
									</div>
								</div>
							</div>
						</div>
  </div>
  <div className="col-md-4">
  <div class="swiper-slide swiper-slide-duplicate swiper-slide-next" data-swiper-slide-index="0">
							<div class="trurnament-inner wow fadeInRight" data-wow-duration="1000ms">
								<div class="torurmant clearfix">
									<div class="tgame game-item">
										<img src="img/team/1.png" alt="Game"/>
										<h4>Afghanistan</h4>
									</div>

									<div class="game-item vs">
										<h4>Vs</h4>
									</div>

									<div class="tgame game-item">
										<img src="img/team/8.png" alt="Game"/>
										<h4>England</h4>
									</div>
								</div>

								<div class="tournaments-details clearfix">
									<div class="tournamant-date">
										<div class="date w-100 text-left">
											<h4>Jun 27</h4>
                      <p>ICC Men's T20 World Cup, 2024</p>
										</div>
									</div>
								</div>
							</div>
						</div>
  </div>
  </div>

			</div>
      
		</section>
    </>
  );
};

export default Match;
