import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import AboutSec from '../components/AboutSec'
import AllTeam from '../components/AllTeam'
import Team from '../components/Team'
import Info from '../components/Info'

const About = () => {
  return (
    <>
      <Breadcrumb title="ABOUT US" />
      <AboutSec />
      <Info />
      <AllTeam />
    </>
  )
}

export default About