import "../App.css";

// css files
import "../css/bootstrap.min.css";
import "../css/animate.min.css";
import "../css/magnific-popup.css";
import "../css/fontawesome-all.min.css";
import "../css/odometer.css";
import "../css/aos.css";
import "../css/owl.carousel.min.css";
import "../css/meanmenu.css";
import "../css/slick.css";
import "../css/default.css";
import "../css/style.css";
import "../css/responsive.css";

import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Layout from "../layout/Layout";
import Players from "../pages/Players";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Play from "../pages/Play";
import Privacy from "../pages/Privacy";
import Refund from "../pages/Refund";
import Terms from "../pages/Terms";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="players" element={<Players />} />
          <Route path="play" element={<Play />} />
          <Route path="contact" element={<Contact />} />
          <Route path="policy" element={<Privacy />} />
          <Route path="refund" element={<Refund />} />
          <Route path="terms" element={<Terms />} />
          {/* <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>
    </>
  );
}

export default App;
