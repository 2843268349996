import React from "react";
import Hero from "../components/Hero";
import About from "../components/AboutSec";
import Steps from "../components/Steps";
import Match from "../components/Match";
import Team from "../components/Team";
import ContactUs from "../components/ContactUs";
import AllTeam from "../components/AllTeam";

const Home = () => {
  return (
    <>
      <Hero />
      <About />
      <Steps />
      <Match />
      <AllTeam />
      <ContactUs />
    </>
  );
};

export default Home;
