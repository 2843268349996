import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<>
			<footer id="footer">
				<div className="container">
					<div className="row">
						<div className="col-md-5">
							<ul className="copy-right" style={{ border: "0" }}>
								<li>
									<p>Copyright 2024
										All Rights Reserved</p>
								</li>
							</ul>
						</div>
						<div className="col-md-7">
							<div className="ss">
								<ul className="d-md-flex copy-right" style={{ border: "0" }}>
									<li className=" ml-3 text-light">
										<Link to="/terms">Terms &amp; Conditions</Link>
									</li>
									<li className="ml-3 text-light">
										<Link to="/policy">Privacy Policy</Link>
									</li>
									<li className="ml-3 text-light">
										<Link to="/refund">Refund and Cancellation Policy</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</footer>

		</>
	);
};

export default Footer;
