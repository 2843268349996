import React from "react";
import { SiGameloft } from "react-icons/si";
import { PiGameControllerFill } from "react-icons/pi";
import { SiNintendogamecube } from "react-icons/si";
import { MdGames } from "react-icons/md";

const Steps = () => {
  return (
    <>
      <section
        class="section-padding"
        id="behind"
        data-parallax="image"
        data-bg-image="media/blog/57.jpg"
      >
        <div class="container">
          <div class="section-title">
            <h2 class="title wow fadeInUp" style={{ color: "#0b0b15" }}>
              FEATURES <span>WE PROVIDE</span>
            </h2>
            <div
              class="section-content wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="0.4s"
            >
              <p className="text-dark">
                Compete with 100 player on a remote island for winner known
                issue where certain strategic
              </p>
            </div>
          </div>
          <div
            class="behind-items wow fadeIn"
            data-wow-duration="1000ms"
            data-wow-delay="0.7s"
          >
            <div class="row">
              <div class="col-md-6">
                <div class="row ">
                  <div class="item bg__one skew__left w-100">
                    <div class="items-content">
                      <div class="icone-wrap">
                        <PiGameControllerFill className="f-72" />
                      </div>

                      <h3 class="title">Earn Real Money</h3>

                      <p class="content">
                        Play Cricket Online to Earn Real Money.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="row">
                  <div class="item bg__two skew__left w-100">
                    <div class="items-content">
                      <div class="icone-wrap">
                        <SiGameloft className="f-72" />
                      </div>

                      <h3 class="title">Fair Game Play</h3>

                      <p class="content">
                        myfantasy11 assures each & every game to be Fair.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="row">
                  <div class="item bg__two skew__right w-100">
                    <div class="items-content">
                      <div class="icone-wrap">
                        <SiNintendogamecube className="f-72" />
                      </div>

                      <h3 class="title">Instant Withdrawal</h3>

                      <p class="content">
                        You can get your money in Just One click withdrawal.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="row">
                  <div class="item bg__one skew__right w-100">
                    <div class="items-content">
                      <div class="icone-wrap">
                        <MdGames className="f-72" />
                      </div>

                      <h3 class="title">Secure</h3>

                      <p class="content">Myfantasy11 is 100% Safe & Secure.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Steps;
