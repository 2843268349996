import React from "react";
import Info from "./Info";

const AboutSec = () => {
  return (
    <>
      <section id="beyond">
        <div class="container">
          <div class="section-title">
            <h2 class="title wow fadeInUp" style={{ color: "rgb(11, 11, 21)" }}>
              ABOUT <span>US</span>
            </h2>
            <span
              class="delemitter  wow fadeIn"
              data-wow-duration="1000ms"
              data-wow-delay="0.4s"
              style={{ backgroundImage: "url(media/banner/s1.png)" }}
            ></span>
          </div>

          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="beyond-content">
                <div class="section-mini-title mb-3">
                  <h3 class="sub-title">PLAY FANTASY GAME ON MYFANTASY11</h3>
                </div>

                <p className="text-dark">
                  Want to enjoy fantasy games like cricket but just can't manage
                  the time? Well, myfantasy11 is the answer you need. This is
                  the place where your favorite fantasy sports come alive. Enjoy
                  playing fantasy cricket right on your device. Pick teams of
                  your choice and play the game. At this platform, We Provide
                  only the best UI and Support completely with passion
                  simplicity and creativity! Fantasy cricket is a strategy based
                  online sports game where players create a virtual team of real
                  cricketers playing in real matches worldwide. You earn points
                  based on the performances of these players in actual matches.
                  In a fantasy cricket match, you can create a team by choosing
                  11 players where you can pick a maximum of 7 players from any
                  one team, playing the real-life match.
                </p>
              </div>
            </div>

            <div class="col-md-6">
              <div class="game-items">
                <img src="img/bg/about.jpg" alt="game-thumb" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutSec;
