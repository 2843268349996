import React from "react";
import OwlCarousel from "react-owl-carousel";

const captainsData = [
  {
    id: 1,
    img: "img/bg/337.webp",
  },
  {
    id: 2,
    img: "img/bg/338.webp",
  },
];

const Hero = () => {

  const options = {
    loop: true,
    items: 1,
    margin: 0,
    autoplay: true,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  return (
    <>
      <section class="">
      <OwlCarousel className="owl-theme" {...options}>
              {captainsData.map((item, index) => (
                <div className="item" key={index}>
                 <img className="w-100" src={item.img} alt=""  style={{ marginTop: "-25px" }}/>
                </div>
              ))}
            </OwlCarousel>
      </section>
    </>
  );
};

export default Hero;
