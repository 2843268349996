import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import ContactUs from '../components/ContactUs'

const Contact = () => {
    return (
        <>
            <Breadcrumb title="Contact us" />
            <ContactUs />
        </>
    )
}

export default Contact